/*=== MEDIA QUERY ===*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Roboto:300,400,500,700");
html {
  font-size: 16px;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-weight: 600;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2.25rem;
}

h4 {
  font-size: 1.6875rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.25rem;
}

p, a, li, blockquote, label {
  font-size: 1rem;
  line-height: 26px;
  color: #848484;
  margin-bottom: 0;
}

.form-control::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 0.9375rem;
}

ul.social-links {
  margin-bottom: 0;
}

ul.social-links li:first-child a {
  padding-left: 0;
}

ul.social-links li a {
  padding: 0 15px;
  display: block;
}

ul.social-links li a i {
  font-size: 20px;
  color: #222222;
}

.shadow, .speakers.white .speaker-item:hover, .schedule.two .schedule-contents, .sponsors .block .image-block:hover, ul.pagination li a:hover, ul.pagination li a:focus, ul.pagination .active a, .news-hr .news-post-hr:hover, .testimonial .testimonial-item .comment:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.overlay:before {
  content: '';
  background: url(../images/background/objects.png) no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay:after {
  content: '';
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: .25;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-lighter:before {
  content: '';
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: .20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text-overlay:after {
  content: 'CONFERENCE';
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 215px;
  width: 100%;
  height: 300px;
  background-position: center center;
  position: absolute;
  top: 40%;
  left: 50%;
  opacity: .05;
  transform: translateX(-50%);
}

.overlay-primary:before {
  content: '';
  background: rgba(255, 102, 0, 0.93);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-dark:before {
  content: '';
  background: -webkit-linear-gradient(-45deg, #1e5799 0%, #1d1546 0%, #1a0b25 100%, #207cca 100%);
  opacity: .75;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white:before {
  content: '';
  background: linear-gradient(0deg, rgba(245, 245, 248, 0.8) 0%, #f7f7f7 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-white-slant:before {
  content: '';
  width: 100%;
  margin-left: -45%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: skewX(-20deg);
}

span.alternate {
  color: #BF5700;
  font-style: italic;
  font-weight: 400;
}

a {
  transition: 0.2s ease;
}

a:focus, a:hover {
  color: #BF5700;
  text-decoration: none;
}

button:hover {
  cursor: pointer;
}

.bg-gray {
  background: #fafafa;
}

.bg-blue {
  background: #BF5700;
}

.bg-banner-one {
  background: url(../images/background/homepage-one-banner.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-banner-two {
  background: url(../images/background/banner-bg-two.jpg) no-repeat;
  background-size: cover;
  background-position: right center;
}

.bg-about {
  background: url(../images/background/pattern-background.jpg) no-repeat;
  background-size: cover;
}

.bg-speaker {
  background: url(../images/background/spaeker-background.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-timer {
  background: url(../images/background/timer-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-schedule {
  background: url(../images/background/schedule-bg.png) no-repeat;
  background-size: cover;
}

.bg-service {
  background: url(../images/background/feature-background.jpg) no-repeat;
  background-size: cover;
}

.bg-registration {
  background: url(../images/background/registration-background.jpg) no-repeat;
  background-size: cover;
}

.bg-registration-two {
  background: url(../images/background/bg-registration-two.jpg) no-repeat;
  background-size: cover;
}

.bg-ticket {
  background: url(../images/background/cta-ticket-bg.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-sponsors {
  background: url(../images/background/sponsors-bg.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-subscribe {
  background: url(../images/background/newsletter-bg.jpg) fixed no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-title {
  background: url(../images/background/page-title-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.section {
  padding: 90px 0;
}

.border-less {
  border-bottom: 1px solid #e5e5e5;
}

.section-title {
  margin-top: 25px;
  text-align: center;
  margin-bottom: 40px;
}

.section-title h3 {
  margin-bottom: 20px;
}

.section-title.white h3, .section-title.white p {
  color: #fff;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.page-title {
  text-align: center;
}

.video {
  position: relative;
}

.video:before {
  border-radius: 3px;
}

.video img {
  width: 100%;
  border-radius: 8px;
}

.video .video-button {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video .video-box a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.video .video-box a i {
  height: 125px;
  width: 125px;
  font-size: 40px;
  background: #BF5700;
  border-radius: 100%;
  color: #fff;
  line-height: 125px;
  text-align: center;
}

.video .video-box a iframe {
  width: 100%;
  height: 100%;
}

.form-control.main {
  background: transparent;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  font-size: 0.9375rem;
  padding: 15px 20px;
  margin-bottom: 20px;
  color: #b3b3b3;
}

.form-control.main:focus {
  border: 1px solid #BF5700;
}

.form-control.white {
  background: #fff;
  color: #222222;
}

select.form-control:not([size]):not([multiple]) {
  height: 100%;
}

.left {
  overflow: hidden;
}

.left img {
  margin-left: -40px;
}

@media (max-width: 767px) {
  .left img {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.right {
  overflow: hidden;
}

.right img {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .right img {
    margin-left: 0;
  }
}

.hide-overflow {
  overflow: hidden;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.border-top {
  border-top: 1px solid;
}

.btn {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  transition: all .3s ease;
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: #BF5700;
  color: #fff;
  outline: none;
}

.btn-main:hover {
  color: #fff;
}

.btn-main:focus {
  color: #fff;
  box-shadow: none;
}

.btn-main-md {
  padding: 15px 40px;
  border-radius: 0;
  background: #BF5700;
  color: #fff;
  outline: none;
  font-size: 0.9375rem;
}

.btn-main-md:hover {
  color: #fff;
  background: #e65c00;
}

.btn-main-md:focus {
  color: #fff;
  box-shadow: none;
}

.btn-transparent-md {
  padding: 15px 40px;
  background: transparent;
  outline: none;
  font-size: 0.9375rem;
  color: #222222;
  border: 1px solid #e5e5e5;
  border-radius: 0;
}

.btn-transparent-md:hover {
  background: #BF5700;
  border-color: #BF5700;
  color: #fff;
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: #BF5700;
  color: #fff;
  outline: none;
  font-size: 14px;
}

.btn-main-sm:hover {
  color: #fff;
}

.btn-main-sm:focus {
  color: #fff;
  box-shadow: none;
}

.btn-white-md {
  background: #fff;
  color: #222222;
  padding: 15px 40px;
  border-radius: 0;
}

.btn-white-md:hover {
  background: #BF5700;
  color: #fff;
}

.btn-rounded-icon {
  border-radius: 100px;
  color: #fff;
  border: 1px solid #fff;
  padding: 13px 50px;
}

.main-nav {
  background: #fff;
}

.main-nav .navbar-brand {
  padding: 35px 40px 35px 50px;
  border-right: 1px solid #e5e5e5;
}

@media (max-width: 1024px) {
  .main-nav .navbar-brand {
    padding: 20px 30px;
  }
}

@media (max-width: 767px) {
  .main-nav .navbar-brand {
    border-right: none;
  }
}

.main-nav .navbar-nav .nav-item {
  position: relative;
  font-family: "Roboto", sans-serif;
}

.main-nav .navbar-nav .nav-item .nav-link {
  position: relative;
  text-align: center;
  color: #222222;
  padding-left: 8px;
  padding-right: 20px;
  font-size: 1.06rem;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1024px) {
  .main-nav .navbar-nav .nav-item .nav-link {
    font-size: 0.875rem;
  }
}

.main-nav .navbar-nav .nav-item .nav-link span {
  color: #222222;
  margin-left: 20px;
}

@media (max-width: 1024px) {
  .main-nav .navbar-nav .nav-item .nav-link span {
    margin-left: 10px;
  }
}

.main-nav .navbar-nav .nav-item.active .nav-link {
  color: #BF5700;
}

.main-nav .dropdown-slide {
  position: static;
}

.main-nav .dropdown-slide .open > a, .main-nav .dropdown-slide .open > a:focus, .main-nav .dropdown-slide .open > a:hover {
  background: transparent;
}

.main-nav .dropdown-slide.full-width .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.main-nav .dropdown-slide:hover .dropdown-menu {
  border-top: 3px solid #BF5700;
  display: none;
  opacity: 1;
  display: block;
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
  color: #777;
  transform: translateY(0px);
}

@media (max-width: 767px) {
  .main-nav .dropdown-slide:hover .dropdown-menu {
    transform: none;
    left: auto;
    position: relative;
    text-align: center;
  }
}

.main-nav .dropdown-slide .dropdown-menu {
  min-width: 220px;
  margin-top: 0;
  border-radius: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
  padding: 5px 15px;
  border: 1px solid #ebebeb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: .3s all;
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30px);
  transition: visibility 0.2s, opacity 0.2s, transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
}

.main-nav .dropdown-slide .dropdown-menu .dropdown-item {
  font-size: 15px;
  padding: 10px 0;
  transition: .3s ease;
}

.main-nav .dropdown-slide .dropdown-menu .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.main-nav .dropdown-slide .dropdown-menu .dropdown-item:hover {
  background: #fff;
  color: #BF5700;
}

@media (max-width: 480px) {
  .main-nav .dropdown-slide .dropdown-menu {
    transform: none;
  }
}

.main-nav .ticket {
  background: #BF5700;
  display: block;
  padding: 34px 32px;
}

@media (max-width: 1024px) {
  .main-nav .ticket {
    padding: 23px 14px;
  }
}

.main-nav .ticket img {
  margin-right: 25px;
}

@media (max-width: 1024px) {
  .main-nav .ticket img {
    display: none;
  }
}

.main-nav .ticket span {
  color: #fff;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  border-left: 1px solid #ffa366;
  padding-left: 24px;
}

@media (max-width: 1024px) {
  .main-nav .ticket span {
    padding-left: 0;
    font-size: 1rem;
    border-left: none;
  }
}

.navbar-toggler:focus, .navbar-toggler:hover {
  outline: none;
}

.page-title {
  position: relative;
  padding: 165px 0 65px;
}

.page-title h3, .page-title li, .page-title a {
  color: #fff;
}

.page-title .title h3 {
  font-weight: 500;
  text-transform: uppercase;
}

.page-title .breadcrumb {
  background: transparent;
}

.page-title .breadcrumb .breadcrumb-item {
  float: none;
  display: inline-block;
  text-transform: uppercase;
}

.page-title .breadcrumb .breadcrumb-item.active {
  color: #fff;
}

.page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f105";
  font-family: FontAwesome;
  color: #fff;
}

.footer-main {
  background: #272735;
  padding: 90px 0;
}

@media (max-width: 767px) {
  .footer-main {
    padding: 50px 0;
  }
}

.footer-main .block img {
  margin-bottom: 60px;
}

.footer-main .block ul.social-links-footer {
  margin-bottom: 0;
}

.footer-main .block ul.social-links-footer .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

.footer-main .block ul.social-links-footer li a {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  background: #fff;
}

.footer-main .block ul.social-links-footer li a i {
  line-height: 50px;
}

.footer-main .block ul.social-links-footer li a:hover {
  background: #BF5700;
}

.footer-main .block ul.social-links-footer li a:hover i {
  color: #fff;
}

.subfooter {
  background: #2a2a37;
  padding: 18px 0;
}

.subfooter .copyright-text p {
  font-family: "Montserrat", sans-serif;
}

.subfooter .copyright-text p a {
  color: #BF5700;
}

@media (max-width: 480px) {
  .subfooter .copyright-text {
    text-align: center;
  }
}

.subfooter .to-top {
  display: block;
  height: 45px;
  width: 45px;
  text-align: center;
  background: #BF5700;
  float: right;
}

.subfooter .to-top i {
  font-size: 1.5rem;
  color: #fff;
  line-height: 45px;
}

@media (max-width: 480px) {
  .subfooter .to-top {
    float: none;
    margin: auto;
    margin-top: 30px;
  }
}

.cta-ticket {
  position: relative;
}

.cta-ticket .content-block {
  padding: 100px 0;
}

.cta-ticket .content-block h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
}

.cta-ticket .content-block p {
  color: #e6e6e6;
  margin-bottom: 45px;
}

.cta-ticket .image-block {
  position: absolute;
  right: 5%;
  bottom: 0;
}

@media (max-width: 767px) {
  .cta-ticket .image-block {
    display: none;
  }
}

.cta-subscribe {
  position: relative;
}

.cta-subscribe .content {
  padding: 70px 0;
}

.cta-subscribe .content h3, .cta-subscribe .content p {
  color: #fff;
}

.cta-subscribe .content h3 {
  font-weight: 500;
}

.cta-subscribe .content p {
  font-size: 0.9375rem;
}

.cta-subscribe .subscribe-button button {
  cursor: pointer;
}

@media (max-width: 767px) {
  .cta-subscribe .subscribe-button {
    margin-bottom: 30px;
  }
}

.banner {
  padding: 250px 0 150px;
  position: relative;
}

@media (max-width: 1024px) {
  .banner {
    padding: 100px 0;
  }
}

.banner .block {
  position: relative;
  z-index: 10;
}

.banner .block .timer .syotimer-cell {
  margin-right: 25px;
  margin-bottom: 33px;
  display: inline-block;
}

@media (max-width: 480px) {
  .banner .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.banner .block .timer .syotimer-cell .syotimer-cell__value {
  min-width: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  line-height: 77px;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #fff;
  border: 2px solid #7c7491;
  margin-bottom: 8px;
  border-radius: 100%;
  box-shadow: 3.5px 6.062px 0px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .banner .block .timer .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
  }
}

.banner .block .timer .syotimer-cell .syotimer-cell__unit {
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.banner .block h1 {
  text-transform: uppercase;
  font-size: 5.5rem;
  color: #BF5700;
  font-weight: 500;
  margin-bottom: 5px;
  text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .banner .block h1 {
    font-size: 4rem;
  }
}

.banner .block h2 {
  text-transform: uppercase;
  font-size: 4.625rem;
  color: #fff;
  font-weight: 400;
  text-shadow: 3.5px 6.062px 0px rgba(0, 0, 0, 0.1);
  font-style: italic;
}

@media (max-width: 767px) {
  .banner .block h2 {
    font-size: 3.2rem;
  }
}

.banner .block h6 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 50px;
}

.banner-two {
  padding: 250px 0 150px;
  position: relative;
  overflow: hidden;
}

.banner-two .block h1 {
  color: #BF5700;
  font-size: 88px;
}

.banner-two .block h2 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 74px;
  margin-bottom: 10px;
}

.banner-two .block h3 {
  font-size: 74px;
}

.banner-two .block h6 {
  margin-top: 48px;
  font-weight: 400;
  margin-bottom: 60px;
}

.about .image-block {
  padding: 30px;
}

.about .image-block img {
  border-radius: 100%;
  box-shadow: 0px 0px 59px 0px rgba(11, 29, 66, 0.15);
}

@media (max-width: 767px) {
  .about .image-block {
    margin-bottom: 30px;
    text-align: center;
  }
}

.about .image-block.two {
  padding: 0;
  margin-top: 30px;
}

.about .image-block.two img {
  border-radius: 0;
  transform: translate(30px, -30px);
}

.about .content-block {
  margin-left: 15px;
}

@media (max-width: 767px) {
  .about .content-block {
    text-align: center;
  }
}

.about .content-block h2 {
  margin-bottom: 35px;
}

.about .content-block h2:after {
  content: "";
  width: 60px;
  height: 3px;
  background: #BF5700;
  display: block;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .about .content-block h2:after {
    margin: auto;
  }
}

.about .content-block .description-one {
  margin-bottom: 20px;
}

.about .content-block .description-one p {
  color: #222222;
  font-size: 1.0625rem;
}

.about .content-block .description-two {
  margin-bottom: 30px;
}

.about .content-block .description-two p {
  font-size: 0.9375em;
}

.about .content-block .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 991px) {
  .about .content-block .list-inline-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.speakers {
  position: relative;
}

.speakers.white .speaker-item {
  background: #fff;
  border: 1px solid #e5e5e5;
}

.speakers .speaker-item {
  background: #eae9ec;
  padding: 7px;
  margin-bottom: 30px;
}

.speakers .speaker-item .image {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 480px) {
  .speakers .speaker-item .image img {
    width: 100% !important;
  }
}

.speakers .speaker-item .image .primary-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #BF5700;
  opacity: .85;
  transition: .3s ease;
}

.speakers .speaker-item .image .socials {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: .4s ease;
}

.speakers .speaker-item .image .socials ul {
  width: 50%;
}

@media (max-width: 1024px) {
  .speakers .speaker-item .image .socials ul {
    width: 61%;
  }
}

@media (max-width: 480px) {
  .speakers .speaker-item .image .socials ul {
    width: 31%;
  }
}

.speakers .speaker-item .image .socials ul li.list-inline-item:not(:last-child) {
  margin-right: 7px;
  margin-bottom: 10px;
}

.speakers .speaker-item .image .socials ul li a {
  display: block;
  height: 50px;
  width: 50px;
  text-align: center;
  border: 1px solid #fff;
}

.speakers .speaker-item .image .socials ul li a i {
  font-size: .9375rem;
  color: #fff;
  line-height: 50px;
}

.speakers .speaker-item .image .socials ul li a:hover {
  background: #fff;
}

.speakers .speaker-item .image .socials ul li a:hover i {
  color: #222222;
}

.speakers .speaker-item .image:hover .primary-overlay {
  top: 0;
}

.speakers .speaker-item .image:hover .socials {
  top: 0;
}

.speakers .speaker-item .content h5 a {
  font-size: 22px;
  color: #222222;
  font-weight: 400;
  margin-bottom: 0;
}

.speakers .speaker-item .content h5 a:hover {
  color: #BF5700;
}

.speakers .speaker-item .content p {
  font-size: 0.875rem;
  margin-bottom: 5px;
}

.schedule .schedule-tab {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.schedule .schedule-tab ul li.nav-item {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .schedule .schedule-tab ul li.nav-item {
    width: 100%;
    margin-bottom: 10px;
  }
}

.schedule .schedule-tab ul li.nav-item a {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #BF5700;
  font-weight: 500;
  display: block;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.schedule .schedule-tab ul li.nav-item a span {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 1rem;
  color: #848484;
  font-weight: 400;
}

.schedule .schedule-tab ul li.nav-item a.active {
  background: #BF5700;
  color: #fff;
  position: relative;
}

.schedule .schedule-tab ul li.nav-item a.active span {
  color: #fff;
}

.schedule .schedule-tab ul li.nav-item a.active:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #BF5700 transparent transparent;
}

@media (max-width: 767px) {
  .schedule .schedule-tab ul li.nav-item a.active:after {
    content: none;
  }
}

.schedule .schedule-contents {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
}

.schedule .schedule-contents .schedule-item ul {
  margin-bottom: 0;
}

.schedule .schedule-contents .schedule-item ul li {
  list-style: none;
}

.schedule .schedule-contents .schedule-item ul li div {
  display: inline-block;
}

.schedule .schedule-contents .schedule-item ul li .time {
  width: 20%;
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .time {
    width: 37%;
  }
}

@media (max-width: 480px) {
  .schedule .schedule-contents .schedule-item ul li .time {
    width: 48%;
  }
}

.schedule .schedule-contents .schedule-item ul li .speaker {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .schedule .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .schedule .schedule-contents .schedule-item ul li .speaker img {
    display: none;
  }
}

.schedule .schedule-contents .schedule-item ul li .subject {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule .schedule-contents .schedule-item ul li .subject {
    display: none;
  }
}

@media (max-width: 767px) {
  .schedule .schedule-contents .schedule-item ul li .venue {
    display: none;
  }
}

.schedule .schedule-contents .schedule-item li.headings {
  padding: 22px 40px;
  background: #BF5700;
}

.schedule .schedule-contents .schedule-item li.headings div {
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.schedule .schedule-contents .schedule-item li.schedule-details {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #e5e5e5;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block {
  padding: 10px 40px;
  background: #fff;
  width: 100%;
  transition: .2s ease-in;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div {
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div i {
  font-size: 1.1875em;
  color: #c7c8c9;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div img {
  border-radius: 100%;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div span.time {
  margin-left: 5px;
}

.schedule .schedule-contents .schedule-item li.schedule-details .block div span.name {
  margin-left: 20px;
  transition: .2s ease-in;
}

@media (max-width: 480px) {
  .schedule .schedule-contents .schedule-item li.schedule-details .block div span.name {
    margin-left: 0;
  }
}

.schedule .schedule-contents .schedule-item li.schedule-details .block:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
  transform: scale(1.01);
}

.schedule .schedule-contents .schedule-item li.schedule-details .block:hover span.name {
  color: #BF5700;
}

.schedule .download-button {
  padding-top: 40px;
  margin-bottom: 30px;
}

.schedule.two .schedule-tab {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.schedule.two .schedule-tab ul li.nav-item {
  margin-right: 0;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .schedule.two .schedule-tab ul li.nav-item {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .schedule.two .schedule-tab ul li.nav-item {
    width: 100%;
    margin-bottom: 10px;
  }
}

.schedule.two .schedule-tab ul li.nav-item a {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  color: #222222;
  font-weight: 500;
  display: block;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.schedule.two .schedule-tab ul li.nav-item a span {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 14px;
  color: #848484;
  font-weight: 400;
}

.schedule.two .schedule-tab ul li.nav-item a.active {
  background: #BF5700;
  color: #fff;
  position: relative;
}

.schedule.two .schedule-tab ul li.nav-item a.active span {
  color: #fff;
}

.schedule.two .schedule-tab ul li.nav-item a.active:after {
  content: '';
  position: absolute;
  left: 100%;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #BF5700;
}

@media (max-width: 991px) {
  .schedule.two .schedule-tab ul li.nav-item a.active:after {
    content: none;
  }
}

.schedule.two .schedule-contents {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0;
}

.schedule.two .schedule-contents .schedule-item ul {
  margin-bottom: 0;
}

.schedule.two .schedule-contents .schedule-item ul li {
  list-style: none;
}

.schedule.two .schedule-contents .schedule-item ul li div {
  display: inline-block;
}

.schedule.two .schedule-contents .schedule-item ul li .time {
  width: 20%;
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .time {
    width: 37%;
  }
}

@media (max-width: 480px) {
  .schedule.two .schedule-contents .schedule-item ul li .time {
    width: 48%;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .speaker {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .schedule.two .schedule-contents .schedule-item ul li .speaker {
    width: 50%;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .subject {
  width: 30%;
}

@media (max-width: 991px) {
  .schedule.two .schedule-contents .schedule-item ul li .subject {
    display: none;
  }
}

.schedule.two .schedule-contents .schedule-item ul li .venue {
  width: 18.6%;
}

@media (max-width: 767px) {
  .schedule.two .schedule-contents .schedule-item ul li .venue {
    display: none;
  }
}

.schedule.two .schedule-contents .schedule-item li.headings {
  padding: 25px 0;
  background: #BF5700;
}

.schedule.two .schedule-contents .schedule-item li.headings div {
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block {
  text-align: center;
  padding: 0;
  background: #fff;
  width: 100%;
  transition: .2s ease-in;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div {
  padding-top: 25px;
  padding-bottom: 25px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div i {
  font-size: 1.1875em;
  color: #c7c8c9;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div img {
  border-radius: 100%;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.time {
  margin-left: 0px;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.name {
  margin-left: 0px;
  transition: .2s ease-in;
}

@media (max-width: 480px) {
  .schedule.two .schedule-contents .schedule-item li.schedule-details .block div span.name {
    margin-left: 0;
  }
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block div:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:hover {
  box-shadow: none;
  transform: scale(1);
}

.schedule.two .schedule-contents .schedule-item li.schedule-details .block:hover span.name {
  color: #BF5700;
}

.ticket-feature {
  overflow: hidden;
}

.ticket-feature .block {
  position: relative;
  padding-top: 150px;
  padding-bottom: 110px;
}

.ticket-feature .block .section-title, .ticket-feature .block a {
  position: relative;
}

.ticket-feature .block .section-title h3 {
  font-weight: 500;
}

.ticket-feature .block .timer {
  position: relative;
  margin-top: 60px;
  margin-bottom: 35px;
}

.ticket-feature .block .timer .syotimer-cell {
  margin-right: 25px;
  margin-bottom: 33px;
  display: inline-block;
}

@media (max-width: 480px) {
  .ticket-feature .block .timer .syotimer-cell {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.ticket-feature .block .timer .syotimer-cell .syotimer-cell__value {
  min-width: 80px;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.35);
  background-clip: content-box;
  background: rgba(255, 255, 255, 0.26);
  margin-bottom: 8px;
  border-radius: 100%;
}

@media (max-width: 480px) {
  .ticket-feature .block .timer .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
  }
}

.ticket-feature .block .timer .syotimer-cell .syotimer-cell__unit {
  text-align: center;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.ticket-feature .block-2 {
  position: relative;
  padding: 150px 10%;
  height: 100%;
  background: #BF5700;
}

.ticket-feature .block-2 [class*=col-]:first-child {
  border-right: 1px solid #fc9552;
  border-bottom: 1px solid #fc9552;
}

.ticket-feature .block-2 [class*=col-]:nth-child(2) {
  border-bottom: 1px solid #fc9552;
}

.ticket-feature .block-2 [class*=col-]:nth-child(3) {
  border-right: 1px solid #fc9552;
}

.ticket-feature .block-2 .service-item {
  padding: 30px;
  text-align: center;
}

.ticket-feature .block-2 .service-item i, .ticket-feature .block-2 .service-item h5 {
  color: #fff;
}

.ticket-feature .block-2 .service-item i {
  font-size: 3.25rem;
  margin-bottom: 20px;
}

.ticket-feature .block-2 .service-item h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.ticket-feature .block-2:after {
  content: '';
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 1000px 300px;
  border-color: transparent transparent #BF5700 transparent;
}

@media (max-width: 767px) {
  .ticket-feature .block-2:after {
    content: none;
  }
}

.registration {
  overflow: hidden;
}

.registration .service-block {
  position: relative;
  padding: 120px 10%;
}

.registration .service-block [class*=col-]:first-child {
  border-right: 1px solid #f69351;
  border-bottom: 1px solid #f69351;
}

.registration .service-block [class*=col-]:nth-child(2) {
  border-bottom: 1px solid #f69351;
}

.registration .service-block [class*=col-]:nth-child(3) {
  border-right: 1px solid #f69351;
}

.registration .service-block .service-item {
  padding: 30px;
  text-align: center;
}

.registration .service-block .service-item i, .registration .service-block .service-item h5 {
  color: #fff;
}

.registration .service-block .service-item i {
  font-size: 3.25rem;
  margin-bottom: 20px;
}

.registration .service-block .service-item h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.registration .registration-block {
  position: relative;
  height: 100%;
  padding: 120px 10%;
}

.registration .registration-block .block {
  position: relative;
  z-index: 9;
}

.registration .registration-block .block .title {
  margin-bottom: 35px;
}

.registration .registration-block .block .title h3 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}

.registration .registration-block .block .title p {
  color: #b3b3b3;
  line-height: 1;
}

.pricing .pricing-item {
  border: 1px solid #e5e5e5;
}

@media (max-width: 991px) {
  .pricing .pricing-item {
    margin-bottom: 30px;
  }
}

.pricing .pricing-item .pricing-heading {
  padding: 20px 40px 30px 40px;
  background: #fafafa;
  border-bottom: 1px solid #e5e5e5;
}

.pricing .pricing-item .pricing-heading .title h6 {
  text-transform: uppercase;
  font-weight: 400;
  line-height: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.pricing .pricing-item .pricing-heading .price {
  margin-top: 28px;
}

.pricing .pricing-item .pricing-heading .price h2 {
  font-size: 3.625rem;
  font-weight: 400;
  margin-bottom: 0px;
}

.pricing .pricing-item .pricing-heading .price h2 span {
  font-size: 1.5625rem;
}

.pricing .pricing-item .pricing-body {
  padding: 45px 40px;
}

.pricing .pricing-item .pricing-body ul.feature-list li {
  list-style: none;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span {
  margin-right: 15px;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span.available {
  color: #BF5700;
}

.pricing .pricing-item .pricing-body ul.feature-list li p span.unavailable {
  color: #d2d2d2;
}

.pricing .pricing-item .pricing-body ul.feature-list li:not(:last-child) {
  margin-bottom: 15px;
}

.pricing .pricing-item .pricing-footer {
  padding-bottom: 40px;
}

.pricing .pricing-item.featured {
  border: none;
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.pricing .pricing-item.featured .pricing-heading {
  background: #BF5700;
  border-bottom: 1px solid #BF5700;
}

.pricing .pricing-item.featured .pricing-heading .title h6 {
  color: #fff;
  border-bottom: 1px solid #f69351;
}

.pricing .pricing-item.featured .pricing-heading .price {
  margin-top: 28px;
}

.pricing .pricing-item.featured .pricing-heading .price h2 {
  color: #fff;
  font-size: 3.625rem;
  margin-bottom: 0px;
}

.pricing .pricing-item.featured .pricing-heading .price h2 span {
  font-size: 1.5625rem;
}

.pricing .pricing-item.featured .pricing-heading .price p {
  color: #fff;
}

.pricing.two .pricing-item {
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

@media (max-width: 991px) {
  .pricing.two .pricing-item {
    margin-bottom: 30px;
  }
}

.pricing.two .pricing-item .pricing-heading {
  position: relative;
  margin-bottom: 10px;
}

.pricing.two .pricing-item .pricing-heading .title h6 {
  position: relative;
}

.pricing.two .pricing-item .pricing-heading .price {
  position: relative;
}

.pricing.two .pricing-item .pricing-heading:before {
  content: '';
  position: absolute;
  bottom: -25%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 500px 0 0;
  border-color: #fafafa transparent transparent transparent;
}

.pricing.two .pricing-item .pricing-body {
  padding: 70px 40px 45px;
}

.pricing.two .pricing-item .pricing-body ul.feature-list li p span.available {
  color: #BF5700;
}

.pricing.two .pricing-item.featured .pricing-heading:before {
  border-color: #BF5700 transparent transparent transparent;
}

.sponsors {
  position: relative;
}

.sponsors .sponsor-title {
  margin-top: 10px;
  margin-bottom: 30px;
}

.sponsors .sponsor-title h5 {
  color: #BF5700;
}

.sponsors .block {
  margin-bottom: 40px;
}

.sponsors .block .list-inline-item:not(:last-child) {
  margin-right: 15px;
}

.sponsors .block .image-block {
  padding: 45px 0;
  background: #fff;
  width: 244px;
  cursor: pointer;
  transition: all .3s ease;
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.sponsors .block .image-block img {
  height: 55px;
}

.sponsors .block .image-block:hover {
  border: 1px solid #BF5700;
}

.sponsors .sponsor-btn {
  margin-top: 10px;
  margin-bottom: 30px;
}

.map {
  position: relative;
}

.map #map {
  height: 480px;
  color: white;
}

.map .address-block {
  position: absolute;
  padding: 45px 50px 50px 50px;
  top: 80px;
  left: 10%;
  background: #BF5700;
}

@media (max-width: 480px) {
  .map .address-block {
    left: 30px;
  }
}

.map .address-block h4, .map .address-block li {
  color: #fff;
  margin-bottom: 20px;
}

.map .address-block h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

.map .address-block ul.address-list li {
  list-style: none;
  margin-bottom: 10px;
}

.map .address-block ul.address-list li i {
  font-size: 1.125rem;
}

.map .address-block ul.address-list li span {
  margin-left: 15px;
}

.map .address-block a {
  margin-top: 35px;
}

.map.new {
  overflow: hidden;
}

.map.new #map {
  height: 640px;
}

.map.new .address-block {
  left: 60%;
}

.map.new .register {
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 767px) {
  .map.new .register {
    width: 100%;
    position: relative;
  }
}

.map.new .register .block {
  padding: 130px 10%;
}

.map.new .register .block .title {
  position: relative;
  margin-bottom: 65px;
}

.map.new .register .block .title h3 {
  color: #fff;
  font-weight: 400;
}

.map.new .register .block .title p {
  color: #fff;
}

.map.new .register .block .form-control, .map.new .register .block button {
  margin-bottom: 40px;
}

.feature .feature-content h2, .feature .feature-content p {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .feature .feature-content h2, .feature .feature-content p {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .feature .testimonial {
    text-align: center;
  }
}

.feature .testimonial p {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  font-style: italic;
  color: #242424;
}

.feature .testimonial ul.meta li {
  font-size: 12px;
  margin-right: 10px;
}

.feature .testimonial ul.meta li img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

@media (max-width: 480px) {
  .app-features .app-feature {
    margin-bottom: 30px;
  }
}

.app-features .app-explore {
  display: flex;
  justify-content: center !important;
  margin-bottom: 40px;
}

.banner-full .image {
  display: flex;
  justify-content: center;
}

.banner-full .image img {
  height: 625px;
}

@media (max-width: 767px) {
  .banner-full .image {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .banner-full .block {
    text-align: center;
  }
}

.banner-full .block .logo {
  margin-bottom: 40px;
}

.banner-full .block h1 {
  margin-bottom: 40px;
}

.banner-full .block p {
  font-size: 20px;
  margin-bottom: 50px;
}

.banner-full .block .app {
  margin-bottom: 20px;
}

.video-promo {
  padding: 150px 0;
}

.video-promo .content-block {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.video-promo .content-block h2 {
  font-size: 30px;
  color: #fff;
}

.video-promo .content-block p {
  margin-bottom: 30px;
}

.video-promo .content-block a i.video {
  height: 125px;
  width: 125px;
  background: #BF5700;
  display: inline-block;
  font-size: 40px;
  color: #fff;
  text-align: center;
  line-height: 125px;
  border-radius: 100%;
}

.video-promo .content-block a:focus {
  outline: 0;
}

.testimonial .testimonial-slider .item {
  padding-bottom: 10px;
}

.testimonial .testimonial-slider .item .block {
  padding: 40px;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
}

.testimonial .testimonial-slider .item .block .image {
  margin-top: 30px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .testimonial .testimonial-slider .item .block .image {
    flex-grow: 1;
  }
}

.testimonial .testimonial-slider .item .block .image img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.testimonial .testimonial-slider .item .block p {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  color: #888888;
}

.testimonial .testimonial-slider .item .block cite {
  font-style: normal;
  font-size: 14px;
  color: #161616;
}

.testimonial .testimonial-slider .owl-dots .owl-dot:hover span {
  background: #BF5700;
}

.testimonial .testimonial-slider .owl-dots .owl-dot.active span {
  background: #BF5700;
}

.single-speaker .block {
  padding-bottom: 70px;
  border-bottom: 1px solid #e5e5e5;
}

.single-speaker .block .image-block {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 6px;
}

@media (max-width: 767px) {
  .single-speaker .block .image-block {
    margin-bottom: 30px;
  }
}

.single-speaker .block .content-block .name h3 {
  font-weight: 400;
}

.single-speaker .block .content-block .profession {
  margin-bottom: 20px;
}

.single-speaker .block .content-block .profession p {
  color: #BF5700;
  font-size: 0.9375rem;
}

.single-speaker .block .content-block .details {
  margin-bottom: 35px;
}

.single-speaker .block .content-block .details p {
  font-size: 0.9375rem;
}

.single-speaker .block .content-block .details p:not(:last-child) {
  margin-bottom: 35px;
}

.single-speaker .block .content-block .social-profiles h5 {
  font-weight: 400;
  margin-bottom: 25px;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a {
  display: block;
  height: 40px;
  width: 40px;
  background: #fff;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a i {
  line-height: 40px;
}

.single-speaker .block .content-block .social-profiles ul.social-list li a:hover {
  background: #BF5700;
  border: 1px solid #BF5700;
  color: #fff;
}

.single-speaker .block-2 {
  padding-top: 70px;
  padding-bottom: 10px;
}

.single-speaker .block-2 h3, .single-speaker .block-2 p, .single-speaker .block-2 li {
  font-size: 0.9375rem;
}

.single-speaker .block-2 .personal-info h5, .single-speaker .block-2 .skills h5 {
  font-weight: 400;
  margin-bottom: 15px;
}

.single-speaker .block-2 .personal-info p, .single-speaker .block-2 .skills p {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .single-speaker .block-2 .personal-info {
    margin-bottom: 30px;
  }
}

.single-speaker .block-2 .personal-info ul li {
  position: relative;
  list-style: none;
  margin-left: 25px;
}

.single-speaker .block-2 .personal-info ul li:not(:last-child) {
  margin-bottom: 5px;
}

.single-speaker .block-2 .personal-info ul li:before {
  position: absolute;
  left: -25px;
  content: "\f10c";
  font-family: FontAwesome;
  color: #BF5700;
}

.single-speaker .block-2 .skills .skill-bar {
  margin-bottom: 20px;
}

.single-speaker .block-2 .skills .skill-bar p {
  margin-bottom: 5px;
}

.single-speaker .block-2 .skills .skill-bar .progress {
  border-radius: 0;
  overflow: visible;
}

.single-speaker .block-2 .skills .skill-bar .progress .progress-bar {
  background-color: #BF5700;
}

.single-speaker .block-2 .skills .skill-bar .progress .progress-bar span {
  font-family: "Montserrat", sans-serif;
  float: right;
  margin-top: -20px;
  color: #222222;
}

.speakers-full-width {
  overflow: hidden;
}

.speakers-full-width .speaker-slider .speaker-image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.speakers-full-width .speaker-slider .speaker-image img {
  width: 100%;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #BF5700;
  opacity: .85;
  transition: .3s ease;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5, .speakers-full-width .speaker-slider .speaker-image .primary-overlay p, .speakers-full-width .speaker-slider .speaker-image .primary-overlay i {
  color: #fff;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5, .speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5 {
  padding-top: 25%;
  margin-bottom: 10px;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay h5, .speakers-full-width .speaker-slider .speaker-image .primary-overlay ul, .speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  transition: .7s ease;
  transform: translate(0, 30px);
  opacity: 0;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay p {
  margin-bottom: 10px;
}

.speakers-full-width .speaker-slider .speaker-image .primary-overlay ul .list-inline-item:not(:last-child) {
  margin-right: 10px;
}

.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay {
  top: 0;
}

.speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay h5, .speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay p, .speakers-full-width .speaker-slider .speaker-image:hover .primary-overlay ul {
  transform: translate(0, 0);
  opacity: 1;
}

.speakers-full-width .speaker-slider .slick-prev {
  left: 60px;
}

.speakers-full-width .speaker-slider .slick-prev:before {
  content: '\f104';
}

.speakers-full-width .speaker-slider .slick-next {
  right: 60px;
}

.speakers-full-width .speaker-slider .slick-next:before {
  content: '\f105';
}

.speakers-full-width .speaker-slider .slick-prev, .speakers-full-width .speaker-slider .slick-next {
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  z-index: 100;
}

.speakers-full-width .speaker-slider .slick-prev:hover:before, .speakers-full-width .speaker-slider .slick-next:hover:before {
  color: #BF5700;
}

.speakers-full-width .speaker-slider .slick-prev:before, .speakers-full-width .speaker-slider .slick-next:before {
  color: #BF5700;
  font-family: FontAwesome;
}

/* Controls
---------------------------------------------------------------------- */
.controls {
  text-align: center;
  margin-bottom: 40px;
}

.controls .control {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  margin-right: 25px;
}

.controls .control:focus {
  outline: 0;
}

.controls .control:hover {
  color: #BF5700;
}

.mixitup-control-active {
  color: #BF5700;
}

/* Container
---------------------------------------------------------------------- */
.gallery-wrapper {
  /* Target Elements
    ---------------------------------------------------------------------- */
  /* Grid Breakpoints
    ---------------------------------------------------------------------- */
  /* 2 Columns */
  /* 3 Columns */
}

.gallery-wrapper .gallery-item {
  padding: 15px;
}

.gallery-wrapper .gallery-item .image-block {
  padding: 6px;
  border: 1px solid #e5e5e5;
}

.gallery-wrapper .gallery-item .image-block .image {
  position: relative;
  overflow: hidden;
}

.gallery-wrapper .gallery-item .image-block .image img {
  width: 100%;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #BF5700;
  opacity: 0;
  transform: scale(0.8);
  transition: .3s ease;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay a {
  display: block;
  height: 55px;
  width: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.gallery-wrapper .gallery-item .image-block .image .primary-overlay a i {
  color: #fff;
  line-height: 55px;
  font-size: 1.25rem;
}

.gallery-wrapper .gallery-item .image-block .image:hover .primary-overlay {
  opacity: .85;
  transform: scale(1);
}

.gallery-wrapper .mix,
.gallery-wrapper .gap {
  display: inline-block;
  vertical-align: top;
}

.gallery-wrapper .mix {
  background: #fff;
  position: relative;
}

.gallery-wrapper .mix {
  width: calc(100%/2 - (((2 - 1) * 1rem) / 2));
}

@media screen and (min-width: 541px) {
  .gallery-wrapper .mix {
    width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
  }
}

.gallery-wrapper.less-gutter .gallery-item {
  padding: 5px;
}

.gallery-wrapper.less-gutter .gallery-item .image-block {
  padding: 0;
  border: none;
}

@media screen and (min-width: 961px) {
  .gallery-wrapper.less-gutter .mix,
  .gallery-wrapper.less-gutter .gap {
    width: calc(100%/4 - (((4 - 1) * 1rem) / 4));
  }
}

.gallery-full {
  overflow: hidden;
}

.gallery-full .image {
  position: relative;
  overflow: hidden;
  margin-bottom: -1px;
}

.gallery-full .image img {
  width: 100%;
}

.gallery-full .image .primary-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #BF5700;
  opacity: 0;
  transform: scale(0.8);
  transition: .3s ease;
}

.gallery-full .image .primary-overlay a {
  display: block;
  height: 55px;
  width: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.gallery-full .image .primary-overlay a i {
  color: #fff;
  line-height: 55px;
  font-size: 1.25rem;
}

.gallery-full .image:hover .primary-overlay {
  opacity: .85;
  transform: scale(1);
}

.news .blog-post {
  transition: .2s ease;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .news .blog-post .post-thumb img {
    width: 100%;
  }
}

.news .blog-post .post-content {
  position: relative;
  padding: 30px 30px 25px 30px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #e5e5e5;
}

.news .blog-post .post-content .date {
  position: absolute;
  top: -32px;
  left: 30px;
  width: 65px;
  height: 65px;
  text-align: center;
  background: #BF5700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news .blog-post .post-content .date h4 {
  color: #fff;
}

.news .blog-post .post-content .date h4 span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.news .blog-post .post-content .post-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}

.news .blog-post .post-content .post-title h2 {
  margin-top: 10px;
}

.news .blog-post .post-content .post-title h2 a {
  font-size: 1.25rem;
  font-weight: 400;
  color: #222222;
}

.news .blog-post .post-content .post-title h2 a:hover {
  color: #BF5700;
}

.news .blog-post .post-content .post-meta ul {
  margin-bottom: 0;
}

.news .blog-post .post-content .post-meta ul li i {
  margin-right: 7px;
}

.news .blog-post .post-content .post-meta ul li a {
  display: inline-block;
  padding-right: 25px;
  font-size: 0.875rem;
}

.news .blog-post:hover {
  box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
}

.news .blog-post.single {
  margin-bottom: 100px;
}

.news .blog-post.single .post-title {
  padding-top: 35px;
  padding-bottom: 0;
  border: none;
  margin-bottom: 10px;
}

.news .blog-post.single .post-title h3 {
  font-weight: 400;
}

.news .blog-post.single .post-meta {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.news .blog-post.single .post-details p {
  padding: 13px 0;
  font-size: 15px;
}

.news .blog-post.single .post-details .quotes {
  background: #f5f5f8;
  padding: 30px;
  border-left: 3px solid #BF5700;
  margin: 15px 0;
}

.news .blog-post.single .post-details .quotes blockquote {
  font-size: 15px;
  font-style: italic;
}

.news .blog-post.single .post-details .share-block {
  display: flex;
  padding-top: 20px;
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block {
    flex-wrap: wrap;
  }
}

.news .blog-post.single .post-details .share-block p {
  padding: 0;
}

.news .blog-post.single .post-details .share-block .tag {
  flex-basis: 50%;
  align-self: center;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block .tag {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 30px;
    flex-grow: 1;
  }
}

.news .blog-post.single .post-details .share-block .tag p, .news .blog-post.single .post-details .share-block .tag ul {
  display: inline;
  font-family: "Montserrat", sans-serif;
}

.news .blog-post.single .post-details .share-block .tag p {
  color: #222222;
}

.news .blog-post.single .post-details .share-block .tag ul li a {
  color: #848484;
  font-size: 15px;
}

.news .blog-post.single .post-details .share-block .tag ul li a:hover {
  color: #BF5700;
}

.news .blog-post.single .post-details .share-block .share {
  flex-basis: 50%;
  text-align: right;
}

@media (max-width: 991px) {
  .news .blog-post.single .post-details .share-block .share {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
  }
}

.news .blog-post.single .post-details .share-block .share p, .news .blog-post.single .post-details .share-block .share ul {
  display: inline;
  font-family: "Montserrat", sans-serif;
}

.news .blog-post.single .post-details .share-block .share p {
  margin-right: 18px;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share {
  margin-bottom: 0;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share .list-inline-item:not(:last-child) {
  margin-right: 7px;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a {
  display: block;
  height: 38px;
  width: 38px;
  text-align: center;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a i {
  line-height: 38px;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a:hover {
  background: #BF5700;
  border-color: #BF5700;
}

.news .blog-post.single .post-details .share-block .share ul.social-links-share li a:hover i {
  color: #fff;
}

.news .blog-post.single:hover {
  box-shadow: none;
}

.news .comments {
  padding-bottom: 80px;
}

.news .comments h5 {
  font-weight: 400;
  margin-bottom: 10px;
}

.news .comments .comment {
  padding-top: 14px;
}

.news .comments .comment img {
  margin-right: 30px;
}

.news .comments .comment .media-body h6 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.news .comments .comment .media-body p, .news .comments .comment .media-body ul {
  font-size: 15px;
}

.news .comments .comment .media-body p {
  margin-bottom: 14px;
}

.news .comments .comment .media-body ul {
  margin-bottom: 10px;
}

.news .comments .comment .media-body ul li span {
  margin-right: 7px;
}

.news .comments .comment .media-body ul li a {
  color: #BF5700;
}

.news .comment-form h5 {
  font-weight: 400;
  margin-bottom: 30px;
}

.news .comment-form .form-control, .news .comment-form button {
  margin-bottom: 30px;
}

ul.pagination {
  margin-top: 30px;
  margin-bottom: 30px;
}

ul.pagination li {
  margin-right: 10px;
}

ul.pagination li a {
  font-size: 16px;
  padding: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-color: transparent;
  color: #848484;
  border: 1px solid #e5e5e5;
  border-radius: 0 !important;
}

ul.pagination li a:hover, ul.pagination li a:focus {
  background-color: #BF5700 !important;
  color: #fff !important;
  border-color: #BF5700 !important;
}

ul.pagination .active a {
  background-color: #BF5700 !important;
  color: #fff !important;
  border-color: #BF5700 !important;
}

.sidebar {
  padding: 0 20px;
}

.widget {
  margin-bottom: 45px;
}

.widget .widget-header {
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .widget.search {
    margin-top: 30px;
  }
}

.widget.search .input-group .form-control {
  border-right: none;
}

.widget.search .input-group .input-group-addon {
  background: #BF5700;
  color: #fff;
  border: none;
  border-radius: 0;
}

.widget.search .input-group .input-group-addon i {
  font-size: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.widget.category ul.category-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  list-style: none;
}

.widget.category ul.category-list li a {
  font-size: 15px;
  letter-spacing: 0;
  display: block;
}

.widget.category ul.category-list li a:hover {
  color: #BF5700;
}

.widget.category ul.category-list li:last-child {
  margin-bottom: 5px;
}

.widget.tags ul li a {
  display: block;
  padding: 10px 25px;
  border: 1px solid #e5e5e5;
  margin-bottom: 15px;
  font-size: 15px;
}

.widget.tags ul li a:hover {
  background: #BF5700;
  color: #fff;
  border-color: #BF5700;
}

.widget.latest-post .media {
  padding: 15px 0;
}

.widget.latest-post .media:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.widget.latest-post .media img {
  margin-right: 15px;
}

.widget.latest-post .media .media-body h6 a {
  font-size: 15px;
  color: #222222;
  line-height: 1;
  font-weight: 400;
}

.widget.latest-post .media .media-body h6 a:hover {
  color: #BF5700;
}

.widget.latest-post .media .media-body p span {
  margin-right: 10px;
}

.news-hr .news-post-hr {
  display: flex;
}

.news-hr .news-post-hr .post-thumb {
  flex-basis: 50%;
}

.news-hr .news-post-hr .post-thumb img {
  width: 100%;
}

.news-hr .news-post-hr .post-contents {
  flex-basis: 50%;
  padding: 30px;
  position: relative;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #e5e5e5;
}

.news-hr .news-post-hr .post-contents .post-title h6 {
  margin-bottom: 36px;
  margin-top: 5px;
}

.news-hr .news-post-hr .post-contents .post-title h6 a {
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  font-size: 20px;
  color: #222222;
}

.news-hr .news-post-hr .post-contents .post-title h6 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 37px;
  content: "";
  background: #BF5700;
  width: 30px;
  height: 2px;
}

.news-hr .news-post-hr .post-contents .post-exerpts p {
  font-size: 15px;
}

.news-hr .news-post-hr .post-contents .date {
  margin-top: 17px;
  width: 65px;
  height: 65px;
  text-align: center;
  background: #BF5700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-hr .news-post-hr .post-contents .date h4 {
  color: #fff;
  font-weight: 400;
}

.news-hr .news-post-hr .post-contents .date h4 span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.news-hr .news-post-hr .post-contents .more {
  text-align: right;
  margin-top: -25px;
}

.news-hr .news-post-hr .post-contents .more a {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  text-transform: uppercase;
  font-size: 14px;
}

.news-hr .news-post-hr .post-contents:after {
  content: '';
  position: absolute;
  right: 100%;
  bottom: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
}

@media (max-width: 767px) {
  .news-hr .news-post-hr .post-contents:after {
    content: none;
  }
}

.about {
  padding: 120px 0;
}

.testimonial .testimonial-item {
  margin-bottom: 30px;
}

.testimonial .testimonial-item .comment {
  padding: 40px 30px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  position: relative;
  transition: .3s ease;
}

.testimonial .testimonial-item .comment:before {
  content: '';
  position: absolute;
  left: -2px;
  bottom: -27px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 27px 0;
  border-color: transparent #e5e5e5 transparent transparent;
  transition: .3s ease;
}

.testimonial .testimonial-item .comment:after {
  content: '';
  position: absolute;
  left: -1px;
  bottom: -26px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 28px 27px 0;
  border-color: transparent #fff transparent transparent;
  transition: .3s ease;
}

.testimonial .testimonial-item .comment:hover {
  background: #BF5700;
  border: 1px solid #BF5700;
}

.testimonial .testimonial-item .comment:hover p {
  color: #fff;
}

.testimonial .testimonial-item .comment:hover:before {
  border-color: transparent #BF5700 transparent transparent;
}

.testimonial .testimonial-item .comment:hover:after {
  border-color: transparent #BF5700 transparent transparent;
}

.testimonial .testimonial-item .media {
  margin-left: 50px;
}

.testimonial .testimonial-item .media img {
  height: 65px;
  width: 65px;
  border-radius: 100%;
}

.testimonial .testimonial-item .media .media-body {
  margin-left: 15px;
  align-self: center;
}

.testimonial .testimonial-item .media .media-body .name p {
  font-family: "Montserrat", sans-serif;
  color: #222222;
}

.testimonial .testimonial-item .media .media-body .profession p {
  font-size: 0.8126rem;
  color: #BF5700;
}

.error .block {
  margin-top: 60px;
  margin-bottom: 60px;
}

.error .block img {
  margin-bottom: 45px;
}

.error .block h3 {
  font-weight: 400;
  color: #BF5700;
  margin-bottom: 40px;
}

.error .block h3 span {
  font-style: italic;
  color: #222222;
}

.faq .accordion-block #accordion .card, .faq .accordion-block #accordionTwo .card {
  border-radius: 0;
  margin-bottom: 20px;
}

.faq .accordion-block #accordion .card .card-header, .faq .accordion-block #accordionTwo .card .card-header {
  background: #fff;
  padding: 0;
}

.faq .accordion-block #accordion .card .card-header h5 a, .faq .accordion-block #accordionTwo .card .card-header h5 a {
  font-weight: 400;
  color: #222222;
}

.faq .accordion-block #accordion .card .card-header h5 a span, .faq .accordion-block #accordionTwo .card .card-header h5 a span {
  padding: 20px;
  border-right: 1px solid #e5e5e5;
  margin-right: 20px;
}

.faq .accordion-block #accordion .card .card-body, .faq .accordion-block #accordionTwo .card .card-body {
  color: #848484;
  padding: 15px 20px;
}

.faq .accordion-block #accordion .card .collapse.show + .card-header h5 a, .faq .accordion-block #accordionTwo .card .collapse.show + .card-header h5 a {
  color: #BF5700;
}

.contact-form .form-control {
  margin-bottom: 30px;
}

.contact-form button {
  margin-top: 30px;
  margin-bottom: 30px;
}
